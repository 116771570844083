import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModServRep } from '../Model/mod-serv-rep';
import { Prioridad } from '../Model/prioridad';
import { Ticket } from '../Model/ticket';
import { Usuario } from '../Model/usuario';

@Injectable({
  providedIn: 'root'
})
export class ApiserviceService {

  constructor(private http:HttpClient) { }
  baseURL="https://sack-mx.com/API-AS/";
  
  public login(usuario:Usuario)
  {
    return this.http.post<Usuario>(this.baseURL+'Login.php',usuario);
  }
  public CambiaContrasenia(contrasenia,idCliente)
  {
    let param={
      'contrasenia':contrasenia,
      'idCliente':idCliente
    }
    return this.http.post(this.baseURL+'Cambiapass.php',param);
  }
  public CambiarContrasenia(usuario,pass)
  {
    let param={'usuario':usuario,'pass':pass};
    return this.http.post<any>(this.baseURL+'OlvidoPass.php',param);
  }
  public ObtenerPrioridad()
  {
    return this.http.get<Prioridad>(this.baseURL+'prioridad.php');
  }
  public ObtenerModulos()
  {
    return this.http.get<ModServRep>(this.baseURL+'modulosActivos.php');
  }
  public EnviarTicket(data)
  {
    return this.http.post<any>(this.baseURL+'enviarticket.php',data);
  }
  public ObtenerTActivos(idCliente)
  {
    let param={'idCliente':idCliente};
    return this.http.post<Ticket>(this.baseURL+'TActivos.php',param);
  }
  public ObtenerTFinalizados(idCliente)
  {
    let param={'idCliente':idCliente};
    return this.http.post<Ticket>(this.baseURL+'TFinalizados.php',param);
  }
  public DetallesActivos(folio)
  {
    var param={'folio':folio};
    return this.http.post(this.baseURL+'TActivosDetalle.php',param);
  }
}
