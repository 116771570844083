import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NuevoTicketComponent } from './Components/nuevo-ticket/nuevo-ticket.component';
import { DndDirective } from './Components/nuevo-ticket/dnd.directive';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './Components/login/login.component';
import { TicketsactivosComponent } from './Components/ticketsactivos/ticketsactivos.component';
import { TicketsfinalizadosComponent } from './Components/ticketsfinalizados/ticketsfinalizados.component';
import { InicioComponent } from './Components/inicio/inicio.component';
import { CambiarcontraseniaComponent } from './Components/cambiarcontrasenia/cambiarcontrasenia.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    DndDirective,
    NuevoTicketComponent,
    LoginComponent,
    TicketsactivosComponent,
    TicketsfinalizadosComponent,
    InicioComponent,
    CambiarcontraseniaComponent
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFileUploaderModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
