<div class="container-fluid" id="back">
    <div class="row pt-4">
        
        <div class="col-sm-12 " id="logocontainer">
            <h4>Sistema de Atención a Clientes V 4.1.0.1</h4>
            <img src="/assets/SACK_logo_ok.png" id="logo" alt="">
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-sm-4 form">
        </div>
        <div class="col-sm-4 form">
            <h4>Usuario</h4>
            <div class="input-group">
                <input type="text" class="form-control">
                <span class="input-group-btn">
                     <button class="btn" disabled>&nbsp;<i class="fas fa-user"></i></button>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 form">
        </div>
        <div class="col-sm-4 form">
            <h4>Contraseña</h4>
            <div class="input-group">
                <input type="password" id="contra" class="form-control" (keydown.enter)="open(content)">
                <span class="input-group-btn">
                     <button class="btn" (click)="Mostrar()" ><i class="far fa-eye"></i></button>
                </span>
            </div>
        </div>
        <div class="col-sm-4 form">
        </div>
        <div class="col-sm-4 form">
        </div>
        <div class="col-sm-4 form mt-3">
            <a (click)="openOlvido(Olvido)" style="font-size: medium;font-weight: bold;">Olvidé mi contraseña</a>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-4">
            
        </div>
        <div class="col-sm-4">
            <button class="btn" id="entrar" style="float: right;" (click)="open(content)"><i class="fas fa-sign-in-alt"></i>&nbsp;Iniciar sesión</button>
        </div>
    </div>
    <div class="row-mb-6" style="margin-top: -12%;">
        <img src="/assets/descarga.png" id="logoG" alt="">
    </div>
    
        
    
</div>




<!-- Modal -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Cambiar contraseña</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
                <label for="">Nueva contraseña</label>
                <div class="input-group">
                    <input type="password" id="pass" class="form-control">
                    <span class="input-group-btn">
                         <button class="btn" (click)="Mostrar1()"><i class="far fa-eye"></i></button>
                    </span>
                </div>
            </div>
            <div class="col-sm-12">
                <label for="">Confirmar contraseña</label>
                <div class="input-group">
                    <input type="password" id="pass1" class="form-control">
                    <span class="input-group-btn">
                         <button class="btn" (click)="Mostrar2()"><i class="far fa-eye"></i></button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="CambiaContrasenia()">Aceptar</button>
    </div>
</ng-template>




<!-- Modal -->
<ng-template #Olvido let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Generar nueva contraseña</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
                <b><label for=""><i class="far fa-user"></i>&nbsp;Usuario</label></b>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Usuario" id="UsuarioOlvido">
                    
                </div>
            </div>
            
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="CambiarContrasenia()">Aceptar</button>
    </div>
</ng-template>