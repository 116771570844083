import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiserviceService } from 'src/app/Services/apiservice.service';
import { TicketsfinalizadosComponent } from '../ticketsfinalizados/ticketsfinalizados.component';

@Component({
  selector: 'app-ticketsactivos',
  templateUrl: './ticketsactivos.component.html',
  styleUrls: ['./ticketsactivos.component.css']
})
export class TicketsactivosComponent implements OnInit {
  closeResult: string;
  constructor(private router:Router,private apiservice:ApiserviceService,private modalService: NgbModal) { }
  tickets:any=[];
  servicios:any=[];
  ngOnInit(): void {
    this.ValidaSesion();
    this.apiservice.ObtenerTActivos(sessionStorage.getItem('idCliente')).subscribe((data)=>{
      console.log(data);
      if(data!=null)
      {
          this.tickets=data;
      }
      });
  }
  Redirige(path)
  {
    this.router.navigate([path]);
  }
  public ValidaSesion()
  {
    if(sessionStorage.getItem('idCliente')==null)
    {
      this.router.navigate(['']);
    }
  }
  public LogOut()
  {
    sessionStorage.clear();
    this.router.navigate(['']);
  }
  open(content,folio) {
    this.traerSegumiento(folio.parentNode.id);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  traerSegumiento(folio)
  {
    this.servicios=null;
    this.apiservice.DetallesActivos(folio).subscribe((data)=>{
      console.log(data);
      if(data!=null)
      {
          this.servicios=data;
      }
      });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  openAcercaDe(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size:'lg', centered: true, backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
